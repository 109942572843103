
import { defineComponent } from 'vue';
import { Button } from '@/components'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import { parseProxyToObject } from '@/utils'
import store from '@/store'

export default defineComponent({
  name: 'Login',
  components: {
    SuiteInput,
    Button
  },
  data() {
    return {
      email: "",
      password: "",
      loginError: "",
      suiteClient: false,
      loginTypeSuite: null,
      loading:false,
    };
  },
  methods: {
    logIn() {
      this.loginError = "";
      this.loading=true;
      const errors = [
        parseProxyToObject(this.$refs.Password).hasErrors()
      ];
      if (errors.includes(true)) {
      this.loading=false;
        return
    };

      store.dispatch('loginClient', { email: this.email, password: this.password })
        .then((data) => {
          if (data.authToken) {
            this.getClientData();
            this.loading=false;

          } else {
            this.loginError = this.$t('suite_login_error');
            this.loading=false;

          }
        });
    },
    async getClientData() {
      store.dispatch('getClientData', { email: this.email, password: this.password })
        .then((data) => {
          if (data.active2fa) {
            this.goTo2FA();
          } else {
            this.$router.push('/dashboard');
          }
        });
    },
    async goTo2FA() {
      await store.dispatch('setCookieNeeds2FA');
      this.$router.push('/2fa');
    },
    async getLoginType() {
      this.loginError = "";
      this.loading=true;
      const errors = [
        parseProxyToObject(this.$refs.Email).hasErrors()
      ];
      if (errors.includes(true)) return;

      try {
        const data = await store.dispatch('getLoginType', { email: this.email, password: "null" });
        const {success, token} = data
        this.loginTypeSuite = success;

        if (this.loginTypeSuite) {
          this.suiteClient = true;
        } else if (this.loginTypeSuite === false ) {
          window.location.href = `https://devsso.wearetesters.com/${token}/login`;
        } else {
          this.loginError = this.$t('suite_login_error');
        }
      this.loading=false;

      } catch (error) {
        console.error("getLoginType error:", error);
        this.loginError = error.data.payload || this.$t('suite_login_error');
        this.loading=false;

      }
    },
    getBackToEmail(){
        this.suiteClient= !this.suiteClient
        this.password = "";
    }
  }
})
